$grey-dark: #546273;
$grey-darker: #546273;

@import 'bulma';
@import 'bulma-tooltip/dist/css/bulma-tooltip.sass';
@import 'bulma-divider/dist/css/bulma-divider.sass';

a:hover {
  text-decoration: underline;
  color: hsl(217, 71%, 53%);
}

hr {
  background-color: #dbdbdb;
}

.input {
  text-align: right;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

.cashflowTable td {
  text-align: right;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

.cashflowTable thead tr:first-child th {
  border-bottom: 0 !important;
}

.rentResultsColumn {
  margin-top: auto;
}

.expand {
  color: #666;
  font-size: 1.2em;
  line-height: 1rem;
}

.nowrap {
  white-space: nowrap;
}

@media screen and (min-width: 769px) {
  .show-narrow {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .hide-narrow {
    display: none;
  }
}

.height-100-pct {
  height: 100%;
}

.testimonial-box {
  padding: 10px 10px 10px 0px;
}

.has-text-weight-medium {
  font-weight: 500;
}

.social-button {
  color: $grey-light;
  margin: 0px 4px 0px 4px;
}

.tweet-button {
  color: #4ab3f4;
}

.tweet-button:hover {
  color: #1da1f2;
}

.facebook-button {
  color: #4268b2;
}

.facebook-button:hover {
  color: #365899;
}

.email-button {
  color: #757575;
}

.email-button:hover {
  color: #5b5b5b;
}

.margin-bottom-sm {
  margin-bottom: 0.5rem !important;
}

.move-up {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.no-vertical-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.flex {
  display: flex;
}

.field-label {
  flex: 3 0 0;
  margin-right: 1.5rem;
  text-align: right;
  padding-top: 0.375em;
}

.results .field-label {
  flex: 5 0 0;
}

.field-body {
  display: flex;
  flex: 5 1 0;
}

.number-field {
  flex: 1 1;
  margin-bottom: 0;
}

.field-body .number-field {
  margin-bottom: 0;
}

.field-body > .number-field:not(:last-child) {
  margin-right: 0.75rem;
}

.padding-20 {
  padding: 10px;
}

.is-divider-vertical {
  margin: 0px;
}

.is-divider-vertical::before {
  border-left: 2px solid #dbdbdb;
}

body {
  position: relative;
  min-height: 100vh;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem 1.5rem 2rem;
}

.section {
  padding-bottom: 16rem;
}

.explanation-header {
  padding-bottom: 0;
  margin-bottom: -0.5rem;
}
